<template>
	<div id="app" class="ybd" :class="{'is_jz': isJz}">
		<Header v-if="!isPreview" :isSaving="isSaving" :showBtn="showBtn" :hasEdit="hasEdit" :isJz="isJz"></Header>
		<div class="container">
			<LeftNav v-if="!isPreview"></LeftNav>
			<div class="main">
				<router-view ref="childa" @changeSaveStatue="change($event)" @changeWatchFlagStatue="changeHadedit($event)" />
			</div>
		</div>
	</div>
</template>
<script>
import Header from "@/components/Header.vue";
import LeftNav from "@/components/LeftNav.vue";
export default {
	name: "App",
	components: {
		Header,
		LeftNav,
	},
	data(){
		return {
			isSaving: false,
			showBtn: false,
			isPreview: false,
			hasEdit: 0,
			isJz: sessionStorage.getItem('isJz')?true:false
		}
	},
	watch:{
    $route(to){
			this.showBtn = to.path == '/' || to.path == '/questionBank'?true:false;
			const isPreviewArr = ['/preview','/previewPC','/previewMobile','/questionBankSet']
			this.isPreview = isPreviewArr.includes(to.path);
			if(to.query.jzHost){
				this.isJz = true;
				sessionStorage.setItem('isJz',true);
				sessionStorage.setItem('type_id',to.query.type_id);
				sessionStorage.setItem('referrer',to.query.jzHost);
			}
		}
  },
	methods: {
		saveForm(preview){
			this.$refs.childa.saveForm(preview);
		},
		change(isSaving){
			this.isSaving = isSaving;
		},
		changeHadedit(hasEdit){
			this.hasEdit = hasEdit;
		}
	}
};
</script>
<style lang="less" scoped>
.ybd {
	display: flex;
	flex-direction: column;
	height: 100%;
	background-color: #f0f2f5;
	.container {
		flex: 1;
		display: flex;
		overflow: hidden;
		.main {
			flex: 1;
			overflow: hidden;
			.wrap {
				width: 100%;
				height: 100%;
			}
		}
	}
}
.ybd.is_jz{
	background-color: unset;
	.form-inner{
		height: 100%;
		margin: 0 auto;
	}
}
</style>