import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import router from './router'
import '@/assets/css/reset.css'
import '@/assets/fontIcon/iconfont.css'
import store from './store'

Vue.config.productionTip = false
Vue.use(ElementUI)
new Vue({
  router,
  store,
  render: h => h(App),
  mounted() { //新增方法
    document.dispatchEvent(new Event('render-event'))
  },
}).$mount('#app')