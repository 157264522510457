import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const routes = [{
    path: '/template',
    name: '模板',
    component: () => import('../views/template/template.vue')
  },
  {
    path: '/',
    name: '题型',
    component: () => import('../views/questionTypes/questionTypes.vue')
  },
  {
    path: '/questionBank',
    name: '题库',
    component: () => import('../views/questionBank/questionBank.vue')
  },
  {
    path: '/questionBankSet',
    name: '题库设置',
    component: () => import('../views/questionBank/questionBankSet.vue')
  },
  {
    path: '/set',
    name: '设置',
    component: () => import('../views/set/set.vue'),
  },
  {
    path: '/invalidAnswer',
    name: '无效答卷',
    component: () => import('../views/set/invalidAnswer.vue'),
  },
  {
    path: '/share',
    name: '分享',
    component: () => import('../views/share/share.vue')
  },
  {
    path: '/preview',
    name: '预览',
    component: () => import('../views/preview/preview.vue')
  },
  {
    path: '/previewPC',
    name: 'PC预览',
    component: () => import('../views/previewPC/previewPC.vue')
  },
  {
    path: '/previewMobile',
    name: '手机预览',
    component: () => import('../views/previewMobile/previewMobile.vue')
  }
]
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
export default router